import React from 'react';
import PanelStudy from '../../Components/PanelStudy'
import CourseStat from '../../Components/CourseStat'
import './style.sass'
import Api from '../../Service/Api'
import topBanner from '../../image/top-banner.png'
import Popup from '../../Components/Popup'
import Lesson from '../Lessons'
import PopapAfterTest from "../Test/popapAfterTest";
import logoPerekrestok from "../../image/LeftSideBar/logo-perekrestok.png";


export default class MyTraining extends React.Component {

    Api = new Api();

    state = {
        courses: {},
        lessonActive: false,
        showPopapEnd: false,
        userData: {},
    }


    componentDidMount() {
        console.log('status', this.props)
        if (this.props.location.state) {
            this.setState({
                showPopapEnd: this.props.location.state.showPopapEnd
            })
        }
        this.setState({
            lessonActive: false,
        })
        this.Api.getLessonCourses()
            .then(this.getLessonsSuccess)
        this.Api.getUserData()
            .then(this.getUserDataSuccess)
    }

    closeEndPopap = () => {
        this.setState({
            showPopapEnd: false
        })
    }

    changeLessonActive = lessonActive => {
        this.setState({ lessonActive })
        this.props.history.push('/lesson', lessonActive)
    }

    getLessonsSuccess = (courses) => {
        this.setState({ courses })
    }

    getUserDataSuccess = (userData) => {
        this.setState({ userData })
    }

    closePopup = () => {
        const { userData } = this.state;
        // Изменяем значение status
        const newData = { ...userData, user: { ...userData.user, show_first_popup: 1 } };
        // Обновляем состояние
        this.setState({ userData: newData });
        this.Api.setShowSertificate()
            .then()
    }

    _renderLessonPanel = () => {
        const { courses, userData } = this.state
        const {status} = this.props
        if (courses !== undefined && Object.values(courses).length) {
            return (
                <div>
                {/*{userData.user && userData.user['show_first_popup'] === null && userData.user['is_vip'] === 'Blue' && */}
                {/*    <Popup closePopup={this.closePopup}>*/}
                {/*        <p className="popupText">«Добро пожаловать в Pet Parents Club!*/}
                {/*    Мы дарим страховки от Petstory и «Ренессанс Страхование» для всех кошек и собак от 3-х месяцев до 8-ми лет, указанных при регистрации в клубе, которые проживают с тобой на постоянной основе и владельцем которых ты фактически являешься. Промокоды на страховку приходят каждый следующий за датой регистрации понедельник всем новым участникам.»</p>*/}
                {/*    </Popup>*/}
                {/*}*/}
                {Object.values(courses).map((course, index) => {
                    let courseAttr = course.course.attributes
                    let lessonAttr = course.course.lessons
                    let pointTotal = 0
                    if (lessonAttr.length) {
                        return (
                            <div key={courseAttr.created_at}>
                                <div>
                                    <div className={`content_head ${status}`}>
                                        {courseAttr.name}
                                    </div>
                                    <div className="studyPanels">
                                        {(lessonAttr !== undefined && Object.values(lessonAttr).length) ?
                                            Object.values(lessonAttr).map(lesson => {
                                                pointTotal = pointTotal + lesson.attributes.points
                                                return (
                                                    <PanelStudy
                                                        key={lesson.attributes.id}
                                                        lesson={lesson}
                                                        to={{ state: lesson }}
                                                        img={lesson.attributes.image}
                                                        id={lesson.attributes.id}
                                                        onClick={this.changeLessonActive}
                                                        status={status}
                                                    />
                                                )
                                            })
                                            :
                                            undefined
                                        }
                                        <CourseStat courses={course.course} pointTotal={pointTotal} status={status}/>
                                    </div>
                                </div>
                                {/*{index === 2 ?
                                <div>
                                    <div className="content_head">ПИТАНИЕ СОБАК: УЗНАЙТЕ БОЛЬШЕ</div>
                                    <div className="studyPanels">
                                        <div className="CourseStat false">
                                            <div className="head">Статистика курса</div>
                                            <div className="balls">
                                                <div className="icon"><img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAWCAYAAAArdgcFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI3SURBVHgBrVRNbtpAFP7GJlB1UdETlJ6g9AQl626gCVUVBTDqASAnID0B6QEqXKhaqYG6m64hJ6hv0OQEZZOd48l7M2bA2Am2kk+yZzzzvW+e388AeXD0s5qHbmVmfvhRQcGeq/HRxfeKQ3qXsVcaZTXJJt6aORCoq7mQNRyf17KY7RbnMAgM9If8rK2sEZxR+eHipSc9evMBLsaHfUi5UN/hs/7DxPn3ZcgilxDWJ7Vm2116L+kZ7KoekRATFhu8oZ0aOIEMGZ5g0jwzvPb5KfkVhYoOkljQeEE8H9+ai7X4kVeFLYeUqKoRM0bSp/ULjJunCbfa0zMSfQUhals7ZCd82KIrDBGip/coaaH8jULBh9tYYhccr4wgqNIfd8hVx2hQftZhaU3nygspFpi820detH7NVZlywieHyn6dUNtuQCWOCK3ZEHnQ8YbKju21DuLiHALL2tcHoI+2N8gk3J4NTEWx/UYoRYLMCS6Ec3ByrZvXcN/7dwpr7l/tpkXcRoybrPPvROAqYQTi/i4sQnvJedoSThdniKgkS0//IQtEmOrEHR0aefzl7ZVZqtNdcjytx65ct3EZ42+hgHRUzEx3bc90bLHIt6RLSfwadeMyxt90MbHCTRGG/7HqNF1iwKrNN68FrhA9L2N8kNBKC0vZjKumkDihaniJyUFDVYVENxKuGP7HPy+2hZJhCYIKCSy1KLXxxkWkoOPsqodDZgmHAtDBzfVzWrvCveCw8JMHjldJs7kFbJTFYMSWer8AAAAASUVORK5CYII="
                                                    alt=""/></div>
                                                <div className="balls__box">Набрано баллов: <b>0 из 0</b></div>
                                            </div>
                                            <div className="status">
                                                <div className="icon"><img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAWCAYAAADeiIy1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMYSURBVHgBnVZdVtNAFP4mCX2D0x1QVmBcAWUFUAse9UibHhdQWAHtCoAVNLQc9Kg15cFnygqoKzC++kL1EUjGO3eSNumkKN5z2kzu3Lnf/Z8ILKOdXhmj1pSfq2seILYhpEs7ZfpNIcUEkGe4vxvjw+twJr+EhMF5+6kKYR+R0ior1KSUh5AYQ8Q/IK11AqlAiCrz9X4q08Wg7j8O1Bge0X8nOTCh3R0Gi+MazvfGhlFeUKG9K1pVSH5E8m6yPiGww6yoZYJQOCzrOVJv1LoIRJFfC2l/i2UVSL++AcRdWh9gf3hsAr356DKIIKv6ux4eHlxae8TrsrLHSO3HssWe7A899Pc6ZOxpAnaQB3LsgMMlLO2usNr83q938C90vjuClJQ/2eb3/i4BCBX6I3i9sgZSVui4autfva9wbgQl/kkkLunncjEpehDKyzLitQMNpK0IZ5WyUtKCIjrFU8i2fH5aYoefF7UJewm0NZCyAvJyfkI2Gdh/OZmxGkF7MbkJL5i9+7WpViyac6H4jL1693Xdgu6DTQ4Zh416Q8YL3sSdpNQzFHnMS3KgbcQpK07DB3tbi/785VDFHJK7AUqlG6QlbTujnM5suc94dg13pPQiMw0ce0x9NSX5HrVL2sSU+9ZUN6wXuIhimgao8mgZvNjC/1Ljs8/hUw2sIpP0oMObPiXOC1pkzS0VwXWhAl2dm0hnHXBdNGpIOZW53YQddbN5nk8G1aSKYjEpBBHoJWXvJk8Vno4hG2GSPN0s24FpkjmBeWpTlK3fGyrezGsMbynMm4ZsaUXNRoM99yhOAUTZkIqlLo549YrHVXPYA4dQnhmyd/fm+RxQaSXUHOEaUiqhEofcc459Q2sPKL4OYNv6vBRhMdCs4aiTs72R0qB+Qp7Vkrfu8jmYNPzCxLfyMrILFZJo7XiJEh1eGYWF2+qq0ZehEdI8EFvB94lHh74nJf13UpNg/4u6ADuctwJvReFBXc7qIqxg/n0QQnmrSluKsX4Xlfx3BBk52DspUlkMlAPkb4dnrFQrTGmqlfN18o2nt8rzEvoDWRRLfKBRn7MAAAAASUVORK5CYII="
                                                    alt=""/></div>
                                                <div className="balls__box">Статус: &nbsp; <b></b></div>
                                            </div>
                                            <div className="progress false">
                                                <div className="icon"><img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAVCAYAAABR915hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJXSURBVHgBtVZRbtpAFJy3pCg/Rdygzg24QeAEDQ1UbdWAOQHpCQInKJwAClFaqUHQE5SeINwg3KD0t429nV0bx4SC7SgdyZbXersz+97ss4HH4mRQxPuvZTwSgqwwhIViG/DPOSryWkKji/HpMMsy6YnfTUvI+U3OcC2h1nNO/8YxRcCJBPz5PceXt8uk5ZKJTToldwHR5XDGEJ7/CZf1eRRzNnEBTVFSDgXMKaC7T4CkJFwxtX2ogx6G1dXeOUpcLtu0Y02ROwRsEpv6PS+4sfSlI3wId+rA9zr3AmQO7XXjWZKIcMMwesHgPnJqlolwt4BjxH1AIwrefHaQz99EhL7+sFG/p4AR4PksHT4iPAkKh4crawYLKUFU04p5StzdOWHaDSn5/L5sqNqoC42hH7g3CyK/mPV0yb4TzLixlinftqu36hLWO22D+HeD6Qdp9rsY1TuBhl2wAvzb2Jv9HWqbMHDz+FXFPjcmOk6ssAvD6jJ4YLBGC4HMARe4RWN6EfnAnN2zyQCFwk/GdtjRaFC/YoXugUIamF2OTo/o+CoXXlqCfJ4Crm+g1HcKOuH2eN7VEca1ShpfHCALLmsz3me2bx94TKm8tBlJajC2pRooY9xOduI1rqoL3l1kho7EpUv1f0AysVYvkBXG4cbZ4v+yY3sSjAdyrXVIQqoZLNJmjQDP6+Pq9QJJCL5QQWvUMovej2rn8bDk73HjusewdjjiWZYlBfFi6zM7WmdETHcSB/dtkWe+3tu1bLo/kKDJ88jgmAROjACRIGMcLcyI9wO5Z4lftb/FjBGrqyUQ7AAAAABJRU5ErkJggg=="
                                                    alt=""/></div>
                                                <div className="desc">Общий прогресс: <div
                                                    className="textProgress textProgress--mobile">0%</div>
                                                    <div className="line">
                                                        <div className="lineProgress"></div>
                                                    </div>
                                                    <div className="textProgress">0%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            undefined}*/}
                            </div>
                        )
                    }
                })}
                </div>
                // eslint-disable-next-line

            )
        } else {
            return undefined;
        }
    }





    render() {
        const { lessonActive, showPopapEnd } = this.state;
        const { status } = this.props;
        const linkForBanner = status === 'pyaterochka' ? 'https://promo.edadeal.com/pet5' : 'https://app.perekrestok.ru/AEfu/k3v3jmq6'
        if (lessonActive) {
            return (
                <Lesson lesson={lessonActive} />
            )
        }
        return (
            <div>
                {(status === 'pyaterochka' || status === 'perekrestok') &&
                    <a href={linkForBanner} className="top-banner" target="_blank" rel="noopener noreferrer">
                        <img src={topBanner} alt="" className="top-banner_img"/>
                    </a>
                }
                {this._renderLessonPanel()}
                {showPopapEnd &&
                    <PopapAfterTest
                        history={this.props.history}
                        closeEndPopap={this.closeEndPopap}
                    />
                }
            </div>
        )
    }
}
