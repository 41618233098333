import React from 'react'
import './style.sass'
import logo from '../../image/PPClub-logo-blue.png'
import LinkPreview1 from '../../image/link-preview-1.jpg'
import LinkPreview2 from '../../image/link-preview-2.png'
import LinkPreview3 from '../../image/link-preview-3.png'
import LinkPreview4 from '../../image/link-preview-4.png'
import LinkPreview5 from '../../image/link-preview-5.png'
import LinkPreview6 from '../../image/link-preview-6.png'
import VideoBlock from "../../Components/LessonAndTestComponents/VideoBlock";
import LinkPreview from "../../Components/LinkPreview";
import Api from '../../Service/Api'
import Popup from '../../Components/Popup'

export default class PPClubVideo extends React.Component {

    Api = new Api();

    state = {
        userData: {},
    }
    componentDidMount() {
        // this.props.hidePreloader();
        this.Api.getUserData()
            .then(this.getUserDataSuccess)
    }

    getUserDataSuccess = (userData) => {
        this.setState({ userData })
    }

    closePopup = () => {
        const { userData } = this.state;
        // Изменяем значение status
        const newData = { ...userData, user: { ...userData.user, show_first_popup: 1 } };
        // Обновляем состояние
        this.setState({ userData: newData });
        this.Api.setShowSertificate()
            .then()
    }

    render() {
        const { userData } = this.state
        return (
            <div className="PPClubVideo">
                {/*{userData.user && userData.user['show_first_popup'] === null && */}
                {/*    <Popup closePopup={this.closePopup}>*/}
                {/*        <p className="popupText">«Добро пожаловать в Pet Parents Club!*/}
                {/*    Мы дарим страховки от Petstory и «Ренессанс Страхование» для всех кошек и собак от 3-х месяцев до 8-ми лет, указанных при регистрации в клубе, которые проживают с тобой на постоянной основе и владельцем которых ты фактически являешься. Промокоды на страховку приходят каждый следующий за датой регистрации понедельник всем новым участникам.»</p>*/}
                {/*    </Popup>*/}
                {/*}*/}
                <img className="PPClubVieo_logo" src={logo} alt=""/>
                <div className="PPClubVideo_text-block">
                    <p className="PPClubVideo_text">
                        <b>Pet Parents Club</b> призван <b>объединить всех родителей домашних питомцев</b> Mars&nbsp;Россия, а также тех кто еще только планирует завести собаку или кошку.
                    </p>
                    <p className="PPClubVideo_text">
                        Задача <b>Pet Parents Club поддерживать сотрудников</b> в вопросах ухода, воспитания, кормления, заботе о здоровье собак и кошек, делиться знаниями, давать советы и рекомендации, отвечать на вопросы, разбирать интересные кейсы.
                    </p>
                </div>
                <p className="PPClubVideo_text-uppercase">
                    Здесь Вы найдете записи регулярных встреч <b>PET PARENTS CLUB</b> и ссылки на полезные материалы и ресурсы. А также вам доступны образовательные материалы в разделе «Мое обучение»
                </p>
                {/*<h2 className="PPClubVideo_title">*/}
                {/*    Видеоматериалы*/}
                {/*</h2>*/}
                {/*<div className="flex">*/}
                {/*    <div className="flex_el">*/}
                {/*        <div className="flex_el_wrp">*/}
                {/*            <LinkPreview*/}
                {/*                link={'#'}*/}
                {/*                img={LinkPreview3}*/}
                {/*                alt={'Ссылка на Видео'}*/}
                {/*            />*/}
                {/*            <VideoBlock*/}
                {/*                src="https://www.youtube.com/embed/FbZI4bxUGI0"*/}
                {/*                title="https://www.youtube.com/watch?v=FbZI4bxUGI0&feature=emb_logo"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="flex_el">*/}
                {/*        <div className="flex_el_wrp">*/}
                {/*            <LinkPreview*/}
                {/*                link={'#'}*/}
                {/*                img={LinkPreview4}*/}
                {/*                alt={'Ссылка на Видео'}*/}
                {/*            />*/}
                {/*            <VideoBlock*/}
                {/*                src="https://www.youtube.com/embed/LQFTil2S-L0"*/}
                {/*                title="https://www.youtube.com/watch?v=LQFTil2S-L0&feature=emb_logo"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <h2 className="PPClubVideo_title">
                    Полезные ссылки
                </h2>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <LinkPreview
                                link={'https://dogstudy.ru'}
                                img={LinkPreview1}
                                alt={'Ссылка на сайт dogstudy'}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <LinkPreview
                                link={'https://catfriendly.ru'}
                                img={LinkPreview2}
                                alt={'Ссылка на сайт catfriendly'}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <LinkPreview
                                link={'/docs/discount.pdf'}
                                img={LinkPreview5}
                                alt={'Ссылка на pdf файл'}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            <LinkPreview
                                link={'/docs/useful_links.pdf'}
                                img={LinkPreview6}
                                alt={'Ссылка на pdf файл'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
